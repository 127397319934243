#components-layout-demo-side .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
}

.ant-menu-item-group-title {
  display: none;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: rgb(31, 37, 50);
}


.country {
  height: 30px;
  border: 1px solid #d9d9d9;
}

.region {
  height: 30px;
  border: 1px solid #d9d9d9;
}